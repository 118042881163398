import React from 'react';
import { Translate } from 'react-auto-translate/lib/commonjs';
import Messages from './Messages';

const LastWill = ({ user, page }) => {
    return (
        <div className="central-meta">
            <Messages user={user} page={page} />
        </div>
    );
}

export default LastWill;
