import React, { Fragment, useState, useContext, useCallback, useEffect } from 'react';
import PdfViewer from '../Components/PdfViewer';

const GalleryContext = React.createContext();
export const useGalleryContext = () => useContext(GalleryContext);

export function GalleryProvider(props) {
  const [openState, setOpenState] = useState(false);
  const [options, setOptions] = useState();

  const handleUserKeyPress = useCallback(({ keyCode }) => {
    if (keyCode === 27 && openState) {
      setOpenState(false);
    }
  }, [openState]);

  useEffect(() => {
    document.addEventListener("keyup", handleUserKeyPress);
    return () => {
      document.removeEventListener("keyup", handleUserKeyPress);
    }
  }, [handleUserKeyPress]);

  const galleryOpen = (url, type="image") => {
    setOptions({
      url, type
    });
    setOpenState(true);
  };

  const galleryClose = data => {
    setOpenState(false);
  };

  return (
    <GalleryContext.Provider value={{ galleryOpen, galleryClose }}>
      <Fragment>
        {props.children}
        {
          !!openState && 
          <div className="gallery-popup" onClick={galleryClose}>

            {
              options.type === 'image' && 
              <div className="gallery-popup-image" style={{backgroundImage: `url('${options.url}')`}} />
            }

            {
              options.type === 'pdf' && 
              <PdfViewer className="gallery-popup-pdf" url={options.url} />
            }

            {
              options.type === 'audio' &&
              <audio controls src={options.url} className="gallery-popup-audio" />
            }
            
            {
              options.type === 'video' &&
              <video controls src={options.url} className="gallery-popup-video" />
            }
            
            {
              (options.type === 'text' || options.type === 'code') &&
              <iframe src={options.url} className="gallery-popup-text" />
            }
            
            {
              options.type === 'document' &&
              <iframe src={options.url} className="gallery-popup-document" />
            }
          </div>
        }
      </Fragment>
    </GalleryContext.Provider>
  );
}