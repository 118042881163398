import React, { useEffect, useState } from 'react';
import { Translate } from 'react-auto-translate/lib/commonjs';
import { useTranslation } from 'react-i18next';
import LoadingButon from '../../LoadingButton';
import { useUserContext } from '../../../Contexts/userContext';
import FileField from '../../Form/FileField';
import SelectAsync from '../../Form/SelectAsync';
import { Field } from 'react-final-form';
import { usePopupContext } from '../../../Contexts/popupContext';
import { getFileType, getImageUrl, getName } from '../../../Util/common';
import { request } from '../../../Services/networkService';
import debounce from 'debounce';
import { useGalleryContext } from '../../../Contexts/galleryContext';

const Messages = ({ user, page }) => {
    const { user: currentUser } = useUserContext();
    const { popupFormOpen, handleError, popupConfirmOpen } = usePopupContext();
    const { galleryOpen } = useGalleryContext();
    const [messages, setMessages] = useState([]);
    const admin = (user?.Owner || page?.Owner) === currentUser._id;
    const group = user ? user.OwnGroup : page?.OwnGroup;
    const { t } = useTranslation('common');

    const getMembers = async (value, callback) => {
        try {
            if (!group) {
                callback && callback([]);
                return [];
            }

            const response = await request('honoree.members', { id: group });
            const mappedArray = response.map((d) => ({
                name: `${getName(d.User)} - ${d.User.Email}`,
                id: d.User._id,
                email: d.User.Email
            }));

            if (value) {
                return mappedArray.filter(item =>
                    item.name.toLowerCase().includes(value.toLowerCase()) ||
                    item.email.toLowerCase().includes(value.toLowerCase())
                );
            }

            if (callback && !value) {
                callback(mappedArray);
                return;
            }

            return mappedArray;

        } catch (error) {
            handleError(error);
            callback && callback([]);
            return [];
        }
    };

    const getMessages = async () => {
        try {
            if (admin) {
                setMessages(await request('honoree.message.list', { groupAdmin: true, honoreeGroup: group }))
            } else setMessages(await request('honoree.message.list', { groupAdmin: false, honoreeGroup: group }))
        } catch (error) {
            handleError(error)
        }
    }

    const eventFormPopup = () => (
        <div>
            <div className='form-row'>
                <Field component={SelectAsync}
                    name='members'
                    label='Share with'
                    isSearchable
                    isMulti={true}
                    loadOptions={debounce(getMembers, 500)}
                />
            </div>
            <div className='form-row'>
                <Field component={FileField}
                    name="file"
                    type="file"
                    accept="image/*,.pdf,.doc,.docx,.txt,.rtf,audio/*,video/*,.js,.css,.html,.json,.md"
                    label='Upload and share your message with your loved ones.'
                    max={1}
                    filespreview='true'
                    placeholder="Select Thumbnail"
                />
            </div>
        </div>
    );

    const handleFormOpen = async () => {
        popupFormOpen(``, eventFormPopup())
            .then(data => {
                const formData = new FormData();
                formData.append('file', data.file[0], data.file[0].name);
                formData.append('groupAdmin', true);
                formData.append('honoree', group);

                const memberIds = data.members ? data.members.map(member => member.id) : [];
                memberIds.forEach(id => {
                    formData.append('sharedWith', id); // No [] suffix
                });

                request('honoree.message.create', formData)
                    .then(() => getMessages())
                    .catch(err => handleError(err))
            })
            .catch((err) => { handleError(err) })
    }

    const handleDelete = async (id) => {
        try {
            return popupConfirmOpen(t('honoree.confirm'), <Translate>Are you sure you wan to delete this message?</Translate>)
            .then(() => 
                request('honoree.message.delete', { id })
                .then(() => getMessages())
                .catch(handleError)
            )
            .catch(()=>{})
            // request('honoree.message.delete', { id })
            // .then(() => getMessages())
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
        getMessages()
    }, [])

    return (
        <div className="editing-info">
            <h5 className="f-title"><i className="fa fa-star" /> <Translate>Last Will and Messages</Translate></h5>
            <div className='photos-container'>
                {
                    admin &&
                    <div className="photos-holder">
                        <div className="photo-title">
                            <b>Upload messages to your family and friends.</b>
                            <div className="submit-btns text-center">
                                <LoadingButon title={<Translate>Upload</Translate>} onClick={() => handleFormOpen()} />
                            </div>
                        </div>
                    </div>

                }
                {
                    messages && Object.keys(messages).length ?
                        messages.map(message =>
                            <div className="photos-holder" key={message.id}>
                                <div className="photo-title">
                                    {
                                        !admin ?
                                            <b><Translate>Shared with me</Translate></b>
                                            :
                                            <>
                                                <b><Translate>Shared with</Translate></b>
                                                <div className="shared-users">
                                                    {message.sharedWith.map(user =>
                                                        <span key={user._id} className="shared-user" title={user.Email}>
                                                            {user.First_Name + ' ' + user.Last_Name}
                                                        </span>
                                                    )}
                                                </div>
                                            </>
                                    }
                                    <div className="submit-btns">
                                        {
                                            message.uploadedFile &&
                                            <LoadingButon
                                                title={
                                                    <Translate>
                                                        {(['document', 'text'].includes(getFileType(message.uploadedFile)))
                                                            ? 'Download Message'
                                                            : 'View Message'}
                                                    </Translate>
                                                }
                                                onClick={() => galleryOpen(getImageUrl(message.uploadedFile), getFileType(message.uploadedFile))}
                                            />
                                        }
                                        <p className="date">
                                            <Translate>Shared on </Translate>{new Date(message.createdAt).toLocaleDateString()}
                                        </p>
                                        {admin &&
                                            <LoadingButon
                                                className="btn delete"
                                                onClick={() => handleDelete(message._id)}
                                                title={
                                                    <div>
                                                        <i className='ti-trash'></i><Translate>Delete</Translate>
                                                    </div>
                                                }
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        <center>
                            <div className="photos-empty"><Translate>No messages to display</Translate></div>
                        </center>
                }
            </div>
        </div>
    );
}

export default Messages;
