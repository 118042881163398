import axios from 'axios';
import { CONFIG } from '../Config';
import { resolveObj } from '../Util/common';
import { useState } from 'react';
import { usePopupContext } from '../Contexts/popupContext';
import { deviceDetect } from 'react-device-detect';

const requestCache = {};

const endpoints = {
  public: {
    logError: {url: '/log-error', method: 'POST'},
    resendActivationMail: {url: '/resend', method: 'POST'},
    getAds: {url: '/ads'},
    listContactUs: {url: '/contactUs'},
    listAboutUs: {url: '/aboutUs'},
    listHowItWorks: {url: '/howItWorks'},
    listPolicy: {url: '/policy'},
    promotions: {url: '/promos'},
    listPosts: {url: '/listFbPosts'},
    listMemorials: {url: '/memorials'},
    listFeatures: {url: '/features'},
    listFeature: {url: '/feature'},
    listFeatureURL: {url: '/featureURL'},
    listBlogs: {url: '/blogs'},
    listBlog: {url: '/blog'},
    listBlogURL: {url: '/blogURL'},
    listSEO_BlogURL: {url: '/seo_blogURL'}
  },
  common:{
    search: {url: '/search'}
  },
  event: {
    create: {url: '/event', method: 'POST'},
    list: {url: '/event/list/:id'},
    update: {url: '/event', method: 'PUT'},
    delete: {url: '/event/delete', method: 'POST'},
  },
  payment: {
    createIntent: {url: '/payment/create-payment-intent', method: 'POST'},
    getMethods: {url: '/payment/get-payment-methods'},
    purchases: {url: '/payment/purchases'},
    stopRenew: {url: '/payment/stop-renew'},
    deletePM: {url: '/payment/deletePM'}
  },
  page: {
    delete: {url: '/page/:id', method: 'DELETE'},
    create: {url: '/page', method: 'POST'},
    list: {url: '/page/list'},
    get: {url: '/page/:id'},
    update: {url: '/page', method: 'PUT'},
    uploadBackgroundPic: {url: '/page/uploadBackgroundPic', method: 'POST'},
    uploadProfilePic: {url: '/page/uploadProfilePic', method: 'POST'}
  },
  group: {
    amIPartOf: {url: '/group/isPartOf'},
    request: {url: '/group/request'},
    getRequests: {url: '/group/getRequests'},
    setUserStatus: {url: '/group/setUserStatus'},
    posts: {url: '/group/:id/posts'},
    photos: {url: '/group/:id/photos'},
    userActivity: {url: '/group/:id/userActivity'},
    inviteFriend: {url: '/group/inviteFriend'},
    memobjects: {url: '/group/post/memobjects'},
    post: {
      get: {url: '/group/:id/post'},
      create: {url: '/group/:id/post', method: 'POST'},
      update: {url: '/group/post', method: 'PUT'},
      delete: {url: '/group/post/:id', method: 'DELETE'},
      deleteBySale: {url: '/group/post/sale/:id', method: 'DELETE'},
      react: {url: '/group/post/:id/react', method: 'POST'},
      comment: {url: '/group/post/:id/comment', method: 'POST'},
      commentDelete: {url: '/group/post/:id/comment/:commentId', method: 'DELETE'},
      commentReact: {url: '/group/post/:id/comment/:commentId/react', method: 'POST'},
      memobject: {url: '/group/:id/postMemobject', method: 'POST'},
      createSale: {url: '/group/:id/createSale', method: 'POST'},
      autoRenew: {url: '/group/post/autoRenew', method: 'PUT'},
      infinite: {url: '/group/post/infinite', method: 'PUT'},
      report: {url: '/group/post/report', method: 'POST'},
      hidden: {url: '/group/post/hidden'}
    }
  },
  user: {
    login: {url: '/login', method: 'POST'},
    verify: {url: '/verify'},
    update: {url: '/user/update', method: 'POST'},
    updateEmail: {url: '/user/updateEmail', method: 'POST'},
    groupDeny: {url: '/user/groupDeny'},
    notifications: {url: '/user/notifications'},
    notificationsClear: {url: '/user/notificationsClear'},
    notificationsReceive: {url: '/user/notificationsReceive'},
    groups: {url: '/user/groups'},
    get: {url: '/user/byId'},
    uploadProfilePic: {url: '/user/uploadProfilePic', method: 'POST'},
    uploadBackgroundPic: {url: '/user/uploadBackgroundPic', method: 'POST'},
    deleteProfile: {url: '/user', method: 'DELETE'},
    inviteFriend: {url: '/user/inviteFriend'},
    topFriends: {url: '/user/:id/topFriends'},
    listAds: {url: '/user/ads'},
    getAds: {url: '/user/getads'},
    generateQR: {url: '/user/generateQR'},
    generateTOTP: {url: '/generateTOTP', method: 'POST'},
    verifyGAuth: {url: '/verifyGAuth', method: 'POST'},
    verifyTOTP: {url: '/verifyTOTP', method: 'POST'},
    sendTOTP: {url: '/sendTOTP', method: 'POST'},
  },
  tracking: {
    savePublic: {url: '/saveTracking', method: 'POST'},
    save: {url: '/tracking/save', method: 'POST'}
  },
  honoree: {
    create: {url: '/honoree', method: 'POST'},
    update: {url: '/honoree', method: 'PUT'},
    relation: {url: '/honoree/relation', method: 'PUT'},
    verifyRelation: {url: '/verify-honoree-relation'},
    delete: {url: '/honoree/:_id', method: 'DELETE'},
    list: {url: '/honoree/list'},
    get: {url: '/honoree/byId/:id'},
    uploadProfilePic: {url: '/honoree/uploadProfilePic', method: 'POST'},
    uploadBackgroundPic: {url: '/honoree/uploadBackgroundPic', method: 'POST'},
    uploadFamilyMemberPic: {url: '/honoree/uploadFamilyMemberPic', method: 'POST'},
    members: {url: '/honoree/members'},
    message: {
      list: {url: '/honoree/message'},
      create: {url: '/honoree/message', method: 'POST'},
      update: {url: '/honoree/message', method: 'PUT'},
      delete: {url: '/honoree/message/:id', method: 'DELETE'}
    }
  },
  famMember: {
    list: {url: '/famMember/list', method: 'POST'}
  },
  memobjects: {
    find: {url: '/memobjects'}
  },
  admin: {
    sendMail: {url: '/admin/sendmail', method: 'POST'},
    ads: {
      list: {url: '/admin/ads'},
      delete: {url: '/admin/ads/:id', method: 'DELETE'},
      update: {url: '/admin/ads', method: 'PUT'}
    },
    util: {url: '/admin/util'},
    honorees: {
      list: {url: '/admin/honorees'},
    },
    pages: {
      list: {url: '/admin/pages'},
    },
    users: {
      list: {url: '/admin/users'},
      update: {url: '/admin/user', method: 'POST'},
      export: {url: '/admin/users/export'}
    },
    groups: {
      list: {url: '/admin/groups'},
      link: {url: '/admin/groups/link'}
    },
    errors: {
      list: {url: '/admin/errors'},
    },
    memobjects: {
      list: {url: '/admin/memobjects'},
      create: {url: '/admin/memobjects', method: 'POST'},
      update: {url: '/admin/memobjects', method: 'PUT'},
      delete: {url: '/admin/memobjects/:id/:multiple', method: 'DELETE'}
    },
    products: {
      list: {url: '/admin/products'},
      create: {url: '/admin/products', method: 'POST'},
      update: {url: '/admin/products', method: 'PUT'},
      delete: {url: '/admin/products/:id', method: 'DELETE'}
    },
    tokens: {
      list: {url: '/admin/tokens'},
      create: {url: '/admin/tokens', method: 'POST'},
      update: {url: '/admin/tokens', method: 'PUT'},
      delete: {url: '/admin/tokens/:id', method: 'DELETE'}
    },
    orphaned: {
      honorees: {url: '/admin/orphanedhonorees'},
      pages: {url: '/admin/orphanedpages'},
      relink: {url: '/admin/relinkorphan', method: 'POST'}
    }
  },
  ad: {
    tracking: {
      listById: {url: '/ad/tracking/:id'},
      list: {url: '/ad/traking'},
      create: {url: '/ad/tracking', method: 'POST'},
      update: {url: '/ad/tracking', method: 'PUT'},
      delete: {url: '/ad/tracking/:id', method: 'DELETE'},
    }
  }
}

const doRequest = (url, server, data, uploadCb) => {
  const method = server?.method || 'GET';
  const onUploadProgress = p => uploadCb && uploadCb(p.loaded / p.total * 100);
  return axios({ url, method, data, onUploadProgress, params: method === 'GET' ? data : undefined })
    .then(data => data.data !== undefined ? data.data : data);
};

export const userGeo = () => {
  let instance = axios.create();
  let result;
  delete instance.defaults.headers.common['Authorization'];

  result = instance.get("https://geolocation-db.com/json/")

  return result;
}

export const saveTrackingData = async (user, page = '') => {
  const device = deviceDetect();
  const geo = await userGeo();
  let trackingData = {};

  trackingData = {
    geo: geo.data,
    device,
    userId: user.id,
    userNames: (user.First_Name + ' ' + user.Last_Name),
    userEmail: user.Email,
    page: page
  };

  request('tracking.savePublic', trackingData);
}

export const request = (what, data, uploadCb, cacheId=null) => {
  const server = resolveObj(what, endpoints);
  if (server) {
    const url = CONFIG.BACKEND_URL + 
      server.url.split('/')
        .map(str => str.startsWith(':') ? (data instanceof FormData ? data.get(str.substr(1)) : data[str.substr(1)]) : str)
        .join('/'); 
    if (cacheId != null) {
      if (!requestCache[what]) {
        requestCache[what] = {};
      }
      if (!requestCache[what][cacheId]) {
        requestCache[what][cacheId] = doRequest(url, server, data, uploadCb);
      }
      return requestCache[what][cacheId];
    } else {
      return doRequest(url, server, data, uploadCb);
    }
  } else {
    console.error('Network Service: Endpoint not found!');
    return null;
  }
}

export const requestMulti = (arr, setters) => {
  const promises = arr.map(what => request(what));
  axios.all(promises).then(res => res.forEach((data,i) => setters[i] && setters[i](data)));
}

export const useRequest = () => {
  const [isLoading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);
  const [data, setData] = useState(null);
  const { handleError } = usePopupContext();

  const handleRequest = (what, data) => {
      setLoading(true);
      setError(false);

      return request(what, data)
        .then(setData)
        .catch(e => {
          setError(true);
          handleError(e);
        })
        .finally(() => setLoading(false))
  };

  return {isLoading, hasError, data, handleRequest};
};